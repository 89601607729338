import React from "react"
import { Footer } from "../blocks/Footer"
import { SecondaryHero } from "../blocks/hero/secondary/SecondaryHero"
import { ContentContainer } from "../components/ContentContainer"
import { PageCommon } from "../components/PageCommon"
import { ContactFormDiptych } from "../blocks/ContactFormDiptych"
import { BackToTopButton } from "../components/BackToTopButton"

export default () => {
  return (
    <>
      <PageCommon />
      <SecondaryHero />
      <ContentContainer maxWidth="wide">
        <ContactFormDiptych />
      </ContentContainer>
      <Footer />
      <BackToTopButton />
    </>
  )
}
